<template>
    <div v-if='model && !model.is_null'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-6'>
                    <div class='p-inputgroup'>
                    <span class='p-float-label'>
                        <InputText id='plain_slug'
                                   type='text'
                                   v-model='v$.model.slug.$model'
                                   :class="{'p-invalid': v$.model.slug.$errors.length}"
                        />
                        <Button label='Fix' class='p-button-warning' title='Correggi SLUG'
                                @click='model.slug = sluggify(model.slug)' />
                        <Button icon='pi pi-sort-up' class='p-button-info'
                                title='Dal nome'
                                @click='model.slug = sluggify(model.name)' />

                        <label for='plain_slug'>Slug</label>
                    </span>
                    </div>
                </div>
                <div class='field col-12 md:col-3'>
                    <span class='p-float-label'>
                        <Dropdown v-model='v$.model.status.$model'
                                  id='status'
                                  dataKey='value'
                                  :options='dd_abtests_statuses'
                                  optionLabel='label'
                                  :class="{'p-invalid': v$.model.status.$errors.length}"
                                  option-value='value'
                                  :filter='true'
                                  filterPlaceholder='Cerca' />
                        <label for='status'>Stato</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='name'
                                   type='text'
                                   v-model='model.name'
                        />
                        <label for='name'>Nome</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description'
                        :key='"anal_abtests_desc_"+model.id'
                        contentType='html' />
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <InputText id='var_a'
                                   type='text'
                                   v-model='model.var_a'
                        />
                        <label for='var_a'>Parametri per la variante A</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <InputText id='var_b'
                                   type='text'
                                   v-model='model.var_b'
                        />
                        <label for='var_b'>Parametri per la variante B</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-4 md:col-2'>
                    <span class='p-float-label'>
                        <InputNumber id='desired_perc_a'
                                     :use-grouping='false'
                                     :min='1'
                                     :max='99'
                                     suffix=' %'
                                     v-model='model.desired_perc_a'
                                     showButtons buttonLayout='horizontal'
                        />
                        <label for='desired_perc_a'>% in A ( => {{ (100 - model.desired_perc_a) }}% in B)</label>
                    </span>
                </div>

                <div class='field col-4 md:col-2'>
                    <span class='p-float-label'>
                        <Dropdown v-model='v$.model.winner.$model'
                                  id='winner'
                                  dataKey='value'
                                  :options='winner_options'
                                  :class="{'p-invalid': v$.model.winner.$errors.length}"
                                  optionLabel='label'
                                  option-value='value'
                                  :filter='true'
                                  filterPlaceholder='Cerca' />
                        <label for='winner'>Winner</label>
                    </span>
                </div>

                <div class='field col-4 md:col-3 fake_float'>
                    <label>Data di chiusura del test </label>
                    <Calendar
                        :showTime='true'
                        inputId='win_date'
                        v-model='model.win_date'
                        dateFormat='dd/mm/yy'
                    />
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Analytics/Abtests');
import useVuelidate from '@vuelidate/core';

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import DdAbtestsStatuses from '../../../mixins/dd_abtests_statuses';

export default {
    components: {},
    data() {
        return {
            winner_options: [
                { value: '', label: 'Ancora in corso' },
                { value: 'a', label: 'Variante A' },
                { value: 'b', label: 'Variante B' },
            ],

        };
    },
    mounted() {
        this.model = this.initModel(this.row);
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n) {
            this.model = this.initModel(n);
        },
    },
    methods: {
        ...mapActions(['savePlain', 'createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        initModel(fromApi){
            const copy = model.modelCopy();
            if (fromApi === null) {
                return copy;
            }
            Object.keys(copy).forEach(field => {
               if (typeof fromApi[field] !== "undefined"){
                   copy[field] = fromApi[field];
               }
            });
            return copy;
        },
        async save() {
            const result = await this.v$.$validate();
            if (!result) {
                this.ShowError('Errore', 'Alcuni campi del form non sono corretti');
                return;
            }

            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then(resp => {
                this.formIsClean();
                if (changeRoute) {
                    this.navigateToId(resp.id).then(() => {
                        this.ShowSuccess('Ottimo', 'Offerta creata con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Offerta modificata con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
        DdAbtestsStatuses,
    ],
};
</script>
