import tools from '../../../libs/tools';
import { required, between, integer } from '@vuelidate/validators'

const correctWinner = (value, all) => {
    if (all.status === 3){
        return ['a', 'b'].includes(value);
    }
    return ['a', 'b', ''].includes(value);
};

const model = {
    id: 0,
    slug: "",
    name: "",
    description: "",
    desired_perc_a: 50,
    var_a: "",
    var_b: "",
    status: 1,
    winner: "",
    win_date: null,
    user_perc_a: 0,
    user_perc_b: 0,
    last_update: 0,
    translations: {},
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, "user_id"),
    modelValidation: {
        model: {
            slug: {
                required
            },
            desired_perc_a: {
                required,
                between: between(1,99),
                integer
            },
            status: {
                required,
                between: between(1,3),
                integer
            },
            winner: {
                correctWinner
            }
        }
    },
    translationValidation: {
        model: {}
    }
};
